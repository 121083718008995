import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import config from '../config'
const TP_API_URL = config.TP_API_URL;

const columns = [
    {
      name: 'Shop',
      selector: 'shop_name',
      sortable: true,
      cell: row => <div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.shop_name}</div>{row.shop_domain}</div>,
    },
    {
        name: 'Email',
        selector: 'shop_email',
        sortable: true,
        right: false
    },
    {
        name: 'Discount',
        selector: 'discount_value',
        sortable: true,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{row.discount_value}%</div>,
    },
    {
        name: 'Twilio Number',
        selector: 'twilio_number',
        sortable: true,
        right: false
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
        right: true,
        cell: row =><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.status === '1' ? "Active" : "Inactive" }</div>,
    }
];



let source = axios.CancelToken.source();

class Shops extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data:[{}],
        loading:false
    };

    

    getdata = () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        axios.get(TP_API_URL+'admin/shopslist', {
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          this.setState({data:response.data, loading:false});
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }


    routeChange=(data)=> {
        this.props.history.push("/shop/view/"+data.id);
    }

    shownavbar = () =>{
        document.body.classList.remove('hidenav');
    }

    render() {
        return(
            <>
                <Container fluid>
                    <Row>
                        <Col>
                            <h3 className="page-heading">
                                <Button variant="light" onClick={this.shownavbar} size="sm" className="menu mr-5">
                                    <FontAwesomeIcon icon={faBars} className="menuicon" />
                                </Button> 
                                Shops
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col>
                            <Card body>
                                {this.state.loading ? <div className="loading">Loading...</div> : null}
                                <DataTable
                                    title="Manage Shops"
                                    columns={columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    onRowClicked={this.routeChange}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default withRouter(Shops);