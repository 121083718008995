import React, { Component} from 'react';
import axios from 'axios';
import { Container, Col, Row, Card, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPen } from '@fortawesome/free-solid-svg-icons';
import config from '../config'
const TP_API_URL = config.TP_API_URL;

let source = axios.CancelToken.source();

class Settings extends Component {


    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        dataupdate:false,
        delerror:false,
        data:[{}],
        loading:false,
        showSettings:false,
        password:"",
        name:"",
        username:""
    };

    handleClose = () => { 
        this.setState({showSettings:false});
    }

    handleShow1 = () => { this.setState({showSettings:true});}


    handleChange = (event) => {
        this.setState({dataupdate:true});
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    handleSave = (event) => {
        event.preventDefault();
        this.setState({showSettings:false});
        axios.post(TP_API_URL+'admin/settingSave', {
            id:this.state.data.id,
            password:this.state.password,
            name:this.state.name,
            username:this.state.username
        })
        .then((res) => {
            this.getdata();
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    getdata = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        axios.get(TP_API_URL+'admin/setting', {params:{token:userToken},
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          const data = response.data[0];
          if(response.token === '0'){
            localStorage.clear();
            window.location.reload();
          } else {
                this.setState(
                        {
                            data:data, 
                            name:data.name,
                            username:data.username,
                            loading:false,
                        }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    shownavbar = () =>{
        document.body.classList.remove('hidenav');
    }

    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> : 
                <Container fluid className="mt-15">
                    <Row>
                        <Col sm={12}>
                            <Card className="nbcard">
                                <Card.Header as="h5">
                                    <Button variant="light" onClick={this.shownavbar} size="sm" className="menu mr-5">
                                        <FontAwesomeIcon icon={faBars} className="menuicon" />
                                    </Button> 
                                    Admin Details 
                                    <Button variant="light" onClick={this.handleShow1} size="sm" className="float-end">
                                        <FontAwesomeIcon icon={faPen} className="menuicon mr-5" /> Edit
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        <small className="text-muted">Name:</small><br/>
                                        {this.state.data.name}
                                    </p>

                                    <p>
                                        <small className="text-muted">Username:</small><br/>
                                        {this.state.data.username}
                                    </p>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }


                {/*Agent details modal*/}
                <Modal show={this.state.showSettings} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.handleSave}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-15" controlId="password">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} required />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="password">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="username" value={this.state.username} onChange={this.handleChange} required />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="password" placeholder="***********" onChange={this.handleChange}/>
                            </Form.Group>
                            
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                        </Modal.Footer>
                        </Form>
                </Modal>
               
            </>
        
        )
    }
}

export default Settings;