import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import config from '../config'
const TP_API_URL = config.TP_API_URL;

let source = axios.CancelToken.source();

class AgentView extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        dataupdate:false,
        delerror:false,
        data:[{}],
        loading:false,
        showAgentDetails:false,
        showDelete:false,
        name:"",
        username:"",
        email:"",
        contact:"",
        status:"",
        password:""
    };

    handleClose = () => { 
        this.setState({showAgentDetails:false, showDelete:false});
        if(this.state.dataupdate===true){
            this.getdata();
            this.setState({dataupdate:false});
        }
        
    }

    handleShow1 = () => { this.setState({showAgentDetails:true});}
    handleShow4 = () => { this.setState({showDelete:true});}


    handleChange = (event) => {
        this.setState({dataupdate:true});
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    handleSave = (event) => {
        event.preventDefault();
        this.setState({showAgentDetails:false});
        var id = this.props.match.params.id;
        axios.post(TP_API_URL+'admin/agentSave', {
            id:id, 
            name:this.state.name,
            username:this.state.username,
            email:this.state.email,
            contact:this.state.contact,
            status:this.state.status,
            password:this.state.password
        })
        .then((res) => {

        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    handleDelete = (event) => {
        event.preventDefault();
        this.setState({delerror:false});
        var del = event.target.delete.value;
        if(del==='delete'){
            this.setState({showDelete:false});
            var id = this.props.match.params.id;
            axios.post(TP_API_URL+'admin/agentDelete', {
                id:id
            })
            .then((res) => {
                this.props.history.push("/agents");
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });

            
        } else {
            this.setState({delerror:true});
        }
    }

    getdata = () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        var id = this.props.match.params.id;
        axios.get(TP_API_URL+'admin/agentView', {params:{id:id},
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          const data = response.data[0];
          this.setState(
                {
                    data:data, 
                    loading:false,
                    name:data.name,
                    username:data.username,
                    email:data.email,
                    contact:data.contact,
                    status:data.status
                }
            );
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    GoBack = () => {
        this.props.history.goBack();
    }

    render() {
        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> : 
                <Container fluid className="mt-15">
                    <Row>
                        <Col sm={12}>
                            <Card className="nbcard">
                                <Card.Header as="h5">
                                    <Button variant="light" onClick={this.GoBack} size="sm" className="mr-5">
                                        <FontAwesomeIcon icon={faArrowLeft} className="menuicon" /> Back
                                    </Button>
                                    Agent Details 
                                    <Button variant="light" onClick={this.handleShow1} size="sm" className="float-end">
                                        <FontAwesomeIcon icon={faEdit} className="menuicon mr-5" /> Edit
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        <small className="text-muted">Name:</small><br/>
                                        {this.state.name}
                                    </p>

                                    <p>
                                        <small className="text-muted">Username:</small><br/>
                                        {this.state.username}
                                    </p>

                                    <p>
                                        <small className="text-muted">Email:</small><br/>
                                        {this.state.email}
                                    </p>

                                    <p>
                                        <small className="text-muted">Phone:</small><br/>
                                        {this.state.contact}
                                    </p>

                                
                                    <p>
                                        <small className="text-muted">Status:</small><br/>
                                        <b>{this.state.status === '1' ? "Active" : "Inactive"}</b>
                                    </p>

                                </Card.Body>
                            </Card>

                            

                            <Card className="nbcard mt-10">
                                <Card.Header as="h5">Delete agent</Card.Header>
                                <Card.Body>
                                    <Button variant="danger" onClick={this.handleShow4}>
                                        Delete this agent
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                }


                {/*Agent details modal*/}
                <Modal show={this.state.showAgentDetails} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.handleSave}>
                        <Modal.Header closeButton>
                            <Modal.Title>Agent Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-15" controlId="s_name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_domain">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="username" value={this.state.username} onChange={this.handleChange}/>
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="password" placeholder="***********" onChange={this.handleChange}/>
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" name="contact" value={this.state.contact} onChange={this.handleChange} />
                            </Form.Group>

                            
                            <Form.Group className="mb-15" controlId="s_status">
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" name="status" custom value={this.state.status} onChange={this.handleChange} >
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </Form.Control>
                            </Form.Group>
                            
                            
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                        </Modal.Footer>
                        </Form>
                </Modal>

                {/*Delete modal*/}
                <Modal show={this.state.showDelete} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.handleDelete}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete agent</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.delerror === true ? <Alert variant="danger">Incorrect "delete msg".</Alert> : ""}
                            <Form.Group className="mb-15" controlId="s_twilio_number">
                                <Form.Label>Type in "delete" to remove this agent from Cart Rescue.</Form.Label>
                                <Form.Control type="text" name="delete" required="true" />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="danger" type="submit">
                            Delete
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        
        )
    }
}

export default withRouter(AgentView);