import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Container, Col, Row, Card, Button, Form, Modal, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBars } from '@fortawesome/free-solid-svg-icons';
import config from '../config'
const TP_API_URL = config.TP_API_URL;
var dateFormat = require("dateformat");
var now = new Date();

const columns = [
    {
      name: 'Name',
      selector: 'username',
      sortable: true,
      cell: row => <div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.username}</div>{row.name}</div>,
    },
    {
        name: 'Contact',
        selector: 'email',
        sortable: true,
        right: false,
        cell: row =><div data-tag="allowRowEvents"><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.email}</div>{row.contact}</div>,
    },
    {
        name: 'Added on',
        selector: 'created_on',
        sortable: true,
        right: false,
        cell: row =><div data-tag="allowRowEvents">{dateFormat(row.created_on)}</div>,
    },
    {
        name: 'Status',
        selector: 'status',
        sortable: true,
        right: true,
        cell: row =><div data-tag="allowRowEvents" style={{ fontWeight: 700 }}>{row.status === '1' ? "Active" : "Inactive" }</div>,
    }
];



let source = axios.CancelToken.source();

class Agents extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        data:[{}],
        loading:false,
        showerror:false,
        error:"",
        showAgentDetails:false,
        name:"",
        username:"",
        email:"",
        contact:"",
        status:1,
        password:""
    };

    handleClose = () => { 
        this.setState({showAgentDetails:false});
    }

    handleShow1 = () => { this.setState({showAgentDetails:true});}
    

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSave = (event) => {
        event.preventDefault();
        this.setState({showerror:false});
        this.setState({error:""});
        axios.post(TP_API_URL+'admin/agentInsert', { 
            name:this.state.name,
            username:this.state.username,
            email:this.state.email,
            contact:this.state.contact,
            status:this.state.status,
            password:this.state.password,
            created_on:dateFormat(now, "yyyy-mm-dd HH:MM:ss")
        })
        .then((res) => {
            const response = res.data;
            if(response.error_code === '1'){
                this.setState({showerror:true});
                this.setState({error:response.error});
            } else {
                this.setState({showerror:false});
                this.setState({error:""});
                this.getdata();
                this.setState({showAgentDetails:false});
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    getdata = () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        axios.get(TP_API_URL+'admin/agentlist', {
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          this.setState({data:response.data, loading:false});
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }


    routeChange=(data)=> {
        this.props.history.push("/agent/view/"+data.id);
    }

    shownavbar = () =>{
        document.body.classList.remove('hidenav');
    }

    render() {
        return(
            <>
                <Container fluid>
                    <Row>
                        <Col>
                            <h3 className="page-heading">
                                <Button variant="light" onClick={this.shownavbar} size="sm" className="menu mr-5">
                                    <FontAwesomeIcon icon={faBars} className="menuicon" />
                                </Button> 
                                Agents
                                <Button variant="info" onClick={this.handleShow1} size="sm" className="float-end">
                                        <FontAwesomeIcon icon={faPlus} className="menuicon mr-5" /> Add new
                                </Button>
                            </h3>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col>
                            <Card body>
                                {this.state.loading ? <div className="loading">Loading...</div> : null}
                                <DataTable
                                    title="Manage Agents"
                                    columns={columns}
                                    data={this.state.data}
                                    highlightOnHover={true}
                                    pointerOnHover={true}
                                    onRowClicked={this.routeChange}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Container>


                {/*Agent details modal*/}
                <Modal show={this.state.showAgentDetails} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.handleSave}>
                        <Modal.Header closeButton>
                            <Modal.Title>Agent Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.showerror === true ? <Alert variant="danger">{this.state.error}</Alert> : ""}
                            <Form.Group className="mb-15" controlId="s_name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} required={true} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_domain">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" name="username" value={this.state.username} onChange={this.handleChange} required={true} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="text" name="password" placeholder={this.state.password} onChange={this.handleChange} required={true} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={this.state.email} onChange={this.handleChange} required={true} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" name="contact" value={this.state.contact} onChange={this.handleChange} required={true} />
                            </Form.Group>

                            
                            <Form.Group className="mb-15" controlId="s_status">
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" name="status" custom value={this.state.status} onChange={this.handleChange} >
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </Form.Control>
                            </Form.Group>
                            
                            
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                        </Modal.Footer>
                        </Form>
                </Modal>
            </>
        )
    }
}

export default withRouter(Agents);