import React, { Component} from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Container, Col, Row, Card, Button, Modal, Form, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import config from '../config'
const TP_API_URL = config.TP_API_URL;

var dateFormat = require("dateformat");
var now = new Date();

let source = axios.CancelToken.source();
let isource = axios.CancelToken.source();

class ShopsView extends Component {

    constructor(props) {
        super(props);
        source = axios.CancelToken.source();
    }

    state = {
        dataupdate:false,
        delerror:false,
        data:[{}],
        loading:false,
        showShopDetails:false,
        showSmsDetails:false,
        showFeeDetails:false,
        showDelete:false,
        shop_name:"",
        shop_domain:"",
        shop_email:"",
        phone:"",
        discount_value:"",
        discount_value_pp:"",
        post_purchase:0,
        status:"",
        is_card_activated:"",
        stripe_customer_id:"",
        stripe_customer_email:"",
        twilio_number:"",
        sms_template:"",
        sms_template_pp:"",
        sms_template_ff:"",
        agent_name:"",
        created:"",
        activated:"",
        idata:[{}],
        total:0,
        page:1,
        iloading:false,
        disabled:false,
        shopify_pages:[],
        privacy_policy:"",
        return_policy:"",
        exchange_policy:"",
        faq:"",
        terms:"",
        shipping_policy:"",
        cookie_policy:"",
        in_privacy_policy:0,
        in_return_policy:0,
        in_exchange_policy:0,
        in_faq:0,
        in_terms:0,
        in_shipping_policy:0,
        in_cookie_policy:0,
        
    };


    setPage = (page) => {
        this.setState({page:page});
    }

    getinvoice = () => {
        let id = this.props.match.params.id;
        if (isource) { isource.cancel();}
        isource = axios.CancelToken.source();

        this.setState({idata:[{}], iloading:true});
        axios.get(TP_API_URL+'admin/shopsInvoice', {
            params:{
                page:this.state.page,
                id:id
            },
            cancelToken: isource.token
        })
        .then((res) => {
          const response = res.data;
          this.setState({idata:response.data, total:response.total, iloading:false});
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    handleClose = () => { 
        this.setState({showShopDetails:false, showSmsDetails:false, showFeeDetails:false, showDelete:false});
        if(this.state.dataupdate===true){
            this.getdata();
            this.setState({dataupdate:false});
        }
        
    }

    handleShow1 = () => { this.setState({showShopDetails:true});}
    handleShow2 = () => { this.setState({showSmsDetails:true});}
    handleShow3 = () => { this.setState({showFeeDetails:true});}
    handleShow4 = () => { this.setState({showDelete:true});}


    handleChange = (event) => {
        this.setState({dataupdate:true});
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }


    handleSave = (event) => {
        event.preventDefault();
        this.setState({showShopDetails:false, showSmsDetails:false, showFeeDetails:false});

        if(this.state.status === '1' && this.state.activated === '0000-00-00 00:00:00'){
            //activated just now. Update the activation date & time
            //Send activation sms to client and admin
            this.setState({
                activated:dateFormat(now, "yyyy-mm-dd HH:MM:ss")
            });
        }

        var id = this.props.match.params.id;
        axios.post(TP_API_URL+'admin/shopSave', {
            id:id, 
            shop_name:this.state.shop_name,
            shop_domain:this.state.shop_domain,
            shop_email:this.state.shop_email,
            phone:this.state.phone,
            discount_value:this.state.discount_value,
            discount_value_pp:this.state.discount_value_pp,
            post_purchase:this.state.post_purchase,
            status:this.state.status,
            is_card_activated:this.state.is_card_activated,
            stripe_customer_id:this.state.stripe_customer_id,
            stripe_customer_email:this.state.stripe_customer_email,
            twilio_number:this.state.twilio_number,
            sms_template:this.state.sms_template,
            sms_template_pp:this.state.sms_template_pp,
            sms_template_ff:this.state.sms_template_ff,
            agent_name:this.state.agent_name,
            created:this.state.created,
            activated:this.state.activated,
        })
        .then((res) => {

        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    handleDelete = (event) => {
        event.preventDefault();
        this.setState({delerror:false});
        var del = event.target.delete.value;
        if(del==='delete'){
            this.setState({showDelete:false});
            var id = this.props.match.params.id;
            axios.post(TP_API_URL+'admin/shopDelete', {
                id:id
            })
            .then((res) => {
    
            })
            .catch((e) => {
                //yconsole.log(e.message);
            });

            this.props.history.push("/shops/");
        } else {
            this.setState({delerror:true});
        }
    }

    getdata = () => {
        if (source) { source.cancel();}
        source = axios.CancelToken.source();

        this.setState({data:[{}], loading:true});
        var id = this.props.match.params.id;
        axios.get(TP_API_URL+'admin/shopView', {params:{id:id},
            cancelToken: source.token
        })
        .then((res) => {
          const response = res.data;
          const data = response.data[0];
          let pages = JSON.parse(data.pages, true);
          this.setState(
                {
                    data:data, 
                    loading:false,
                    shop_name:data.shop_name,
                    shop_domain:data.main_domain,
                    shop_email:data.shop_email,
                    phone:data.shop_phone,
                    discount_value:data.discount_value,
                    discount_value_pp:data.discount_value_pp,
                    post_purchase:data.post_purchase,
                    status:data.status,
                    twilio_number:data.twilio_number,
                    sms_template:data.sms_template,
                    sms_template_pp:data.sms_template_pp,
                    sms_template_ff:data.sms_template_ff,
                    agent_name:data.agent_name,
                    created:data.created_on,
                    activated:data.activated_on,
                    success:0,
                    error_code:0,
                    error_msg:"",
                    privacy_policy:pages.privacy_policy,
                    return_policy:pages.return_policy,
                    exchange_policy:pages.exchange_policy,
                    faq:pages.faq,
                    terms:pages.terms,
                    shipping_policy:pages.shipping_policy,
                    cookie_policy:pages.cookie_policy,
                    in_privacy_policy:pages.in_privacy_policy,
                    in_return_policy:pages.in_return_policy,
                    in_exchange_policy:pages.in_exchange_policy,
                    in_faq:pages.in_faq,
                    in_terms:pages.in_terms,
                    in_shipping_policy:pages.in_shipping_policy,
                    in_cookie_policy:pages.in_cookie_policy,
                    
                }
            );
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }


    GetShopifyPages = () => {
        let tp_shop = this.props.match.params.id;
        const ax_headers = {
            params:{
                shop_id : tp_shop
            }
        }

        axios.get(TP_API_URL+"admin/shopify-pages", ax_headers)
        .then((res) => {
            const response = res.data;
            if(response.error_code===0){
                this.setState(
                    {
                        loading : false,
                        shopify_pages : response.data
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:response.error_code,
                        error_msg:response.error_msg
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    Update_shop = (event) => {
        event.preventDefault();
        this.setState({
                error_code:0,
                error_msg:"",
                disabled:true,
                success:0
        });

        const page_data = {
            in_privacy_policy:this.state.in_privacy_policy,
            in_return_policy:this.state.in_return_policy,
            in_exchange_policy:this.state.in_exchange_policy,
            in_faq:this.state.in_faq,
            in_terms:this.state.in_terms,
            in_shipping_policy:this.state.in_shipping_policy,
            in_cookie_policy:this.state.in_cookie_policy,
            privacy_policy : this.state.privacy_policy,
            return_policy:this.state.return_policy,
            exchange_policy:this.state.exchange_policy,
            faq:this.state.faq,
            terms:this.state.terms,
            shipping_policy:this.state.shipping_policy,
            cookie_policy:this.state.cookie_policy
        }

        let tp_shop = this.props.match.params.id;

        axios.post(TP_API_URL+"admin/pagesSave", {
            shop_id : tp_shop,
            pages:page_data
        }).then((res) => {
            const response = res.data;
            if(!response.error){
                this.setState(
                    {
                        loading:false,
                        success:1,
                        disabled:false
                    }
                );

            } else {
                this.setState(
                    {
                        loading:false,
                        error_code:'201',
                        error_msg:response.error,
                        disabled:false
                    }
                );
            }
        })
        .catch((e) => {
            //yconsole.log(e.message);
        });
    }

    componentDidMount(){
        this.getdata();
        this.getinvoice();
        this.GetShopifyPages();
    }

    componentWillUnmount() {
        if (source) {
            source.cancel();
        }
    }

    GoBack = () => {
        this.props.history.goBack();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if(prevState.page!==this.state.page){
            this.getinvoice();
        }
    }

    handlePageChange = async (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const id = target.id;
        if(value==='other_0') {
            this.setState({
                [id]:1
            });
        } else {
            this.setState({
                [name]: value,
                [id]:0
            });
        }
    }

    render() {
        let optionTemplate = this.state.shopify_pages.map(v => (
            <option value={v.url} key={v.name}>{v.name}</option>
        ));

        return(
            <>
                {this.state.loading ? <div className="loading">Loading...</div> : 
                <Container fluid className="mt-15">
                    <Row>
                        <Col sm={5}>
                            <Card className="nbcard">
                                <Card.Header as="h5">
                                    <Button variant="light" onClick={this.GoBack} size="sm" className="mr-5">
                                        <FontAwesomeIcon icon={faArrowLeft} className="menuicon" /> Back
                                    </Button>
                                    Shop Details 
                                    <Button variant="light" onClick={this.handleShow1} size="sm" className="float-end">
                                        <FontAwesomeIcon icon={faEdit} className="menuicon mr-5" /> Edit
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        <small className="text-muted">Name:</small><br/>
                                        {this.state.shop_name}
                                    </p>

                                    <p>
                                        <small className="text-muted">Domain:</small><br/>
                                        {this.state.shop_domain}
                                    </p>

                                    <p>
                                        <small className="text-muted">Email:</small><br/>
                                        {this.state.shop_email}
                                    </p>

                                    <p>
                                        <small className="text-muted">Phone:</small><br/>
                                        {this.state.phone}
                                    </p>

                                    <p>
                                        <small className="text-muted">Discount:</small><br/>
                                        {this.state.discount_value}%
                                    </p>

                                    <p>
                                        <small className="text-muted">Post Purchase:</small><br/>
                                        <b>{this.state.post_purchase === '1' ? "Enabled" : "Disabled"}</b>
                                    </p>

                                    <p>
                                        <small className="text-muted">Discount Post Purchase:</small><br/>
                                        {this.state.discount_value_pp}%
                                    </p>

                                    <p>
                                        <small className="text-muted">Status:</small><br/>
                                        <b>{this.state.status === '1' ? "Active" : "Inactive"}</b>
                                    </p>

                                    <p>
                                        <small className="text-muted">Registered on:</small><br/>
                                        {dateFormat(this.state.created)}
                                    </p>

                                    <p>
                                        <small className="text-muted">Activated on:</small><br/>
                                        {this.state.activated === '0000-00-00 00:00:00' ? "Not activated" : dateFormat(this.state.activated)}
                                    </p>
                                </Card.Body>
                            </Card>


                            <Card className="nbcard mt-10 mb-15">
                                <Card.Header as="h5">Delete shop</Card.Header>
                                <Card.Body>
                                    <Button variant="danger" onClick={this.handleShow4}>
                                        Delete this shop
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col sm={7}>
                            <Card className="nbcard">
                                <Card.Header as="h5">
                                    SMS Settings
                                    <Button variant="light" onClick={this.handleShow2} size="sm" className="float-end">
                                        <FontAwesomeIcon icon={faEdit} className="menuicon mr-5" /> Edit
                                    </Button>
                                </Card.Header>
                                <Card.Body>
                                    <p>
                                        <small className="text-muted">Twilio Number:</small><br/>
                                        {this.state.twilio_number}
                                    </p>

                                    <p>
                                        <small className="text-muted">Queue SMS:</small><br/>
                                        {this.state.sms_template}
                                    </p>

                                    <p>
                                        <small className="text-muted">Post Purchase SMS:</small><br/>
                                        {this.state.sms_template_pp}
                                    </p>

                                    <p>
                                        <small className="text-muted">Follow Up SMS:</small><br/>
                                        {this.state.sms_template_ff}
                                    </p>

                                    <p>
                                        <small className="text-muted">Agent Name:</small><br/>
                                        {this.state.agent_name}
                                    </p>
                                </Card.Body>
                            </Card>


                            {/*
                            <Card className="mt-10">
                                <Card.Body className='p-0'>
                                    {this.state.iloading ? <div className="loading">Loading...</div> : null }
                                    <DataTable
                                        title="Invoice"
                                        columns={columns}
                                        data={this.state.idata}
                                        pagination={true}
                                        paginationServer={true}
                                        page={this.state.page}
                                        paginationTotalRows={this.state.total}
                                        paginationPerPage={10}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => this.setPage(page)}
                                        paginationDefaultPage={this.state.page}
                                    />
                                </Card.Body>
                            </Card>  
                             */}


                            <Card className="nbcard mt-20">
                                <Card.Header as="h5">
                                    Shop Pages Settings
                                </Card.Header>
                                <Card.Body>
                                    <Form onSubmit={this.Update_shop}>
                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Privacy policy</b></Form.Label>
                                            <Form.Control as="select" name="privacy_policy" id="in_privacy_policy" value={this.state.privacy_policy} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_privacy_policy ? <Form.Control className='mt-10 py-0' type="text" name="privacy_policy" placeholder='Paste URL' value={this.state.privacy_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Return policy</b></Form.Label>
                                            <Form.Control as="select" name="return_policy" id="in_return_policy" value={this.state.return_policy} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_return_policy ? <Form.Control className='mt-10 py-0' type="text" name="return_policy" placeholder='Paste URL' value={this.state.return_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Exchange policy</b></Form.Label>
                                            <Form.Control as="select" name="exchange_policy" id="in_exchange_policy" value={this.state.exchange_policy} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_exchange_policy ? <Form.Control className='mt-10 py-0' type="text" name="exchange_policy" placeholder='Paste URL' value={this.state.exchange_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>FAQ</b></Form.Label>
                                            <Form.Control as="select" name="faq" id="in_faq" value={this.state.faq} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_faq ? <Form.Control className='mt-10 py-0' type="text" name="faq" placeholder='Paste URL' value={this.state.faq} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Terms and conditions</b></Form.Label>
                                            <Form.Control as="select" name="terms" id="in_terms" value={this.state.terms} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_terms ? <Form.Control className='mt-10 py-0' type="text" name="terms" placeholder='Paste URL' value={this.state.terms} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Shipping policy</b></Form.Label>
                                            <Form.Control as="select" name="shipping_policy" id="in_shipping_policy" value={this.state.shipping_policy} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_shipping_policy ? <Form.Control className='mt-10 py-0' type="text" name="shipping_policy" placeholder='Paste URL' value={this.state.shipping_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        <Form.Group className="mb-20">
                                            <Form.Label><b>Cookie Policy</b></Form.Label>
                                            <Form.Control as="select" name="cookie_policy" id="in_cookie_policy" value={this.state.cookie_policy} onChange={this.handlePageChange}>
                                                <option value="">Select</option>
                                                {optionTemplate}
                                                <option value="other_0">Other</option>
                                            </Form.Control>
                                            {this.state.in_cookie_policy ? <Form.Control className='mt-10 py-0' type="text" name="cookie_policy" placeholder='Paste URL' value={this.state.cookie_policy} onChange={this.handleChange} /> : "" }
                                        </Form.Group>

                                        {this.state.success > 0 ? <Alert variant="success">Shop updated successfully!</Alert> : ""}
                                        {this.state.error_code > 0 ? <Alert variant="danger">{this.state.error_msg}</Alert> : ""}
                                    
                                        <Button variant="primary" type="submit" disabled={this.state.disabled}>
                                                Save
                                        </Button>

                                        
                                    </Form>
                                </Card.Body>
                            </Card> 
                        </Col>
                    </Row>
                </Container>
                }


                {/*Shop details modal*/}
                <Modal show={this.state.showShopDetails} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.handleSave}>
                        <Modal.Header closeButton>
                            <Modal.Title>Shop Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className="mb-15" controlId="s_name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="shop_name" value={this.state.shop_name} onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_domain">
                                <Form.Label>Domain</Form.Label>
                                <Form.Control type="text" name="shop_domain" value={this.state.shop_domain} onChange={this.handleChange}/>
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="shop_email" value={this.state.shop_email} onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_phone">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" name="phone" value={this.state.phone} onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_discount">
                                <Form.Label>Discount %</Form.Label>
                                <Form.Control type="number" name="discount_value" value={this.state.discount_value} onChange={this.handleChange}/>
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_post_purchase">
                                <Form.Label>Post Purchase</Form.Label>
                                <Form.Control as="select" name="post_purchase" custom value={this.state.post_purchase} onChange={this.handleChange} >
                                    <option value="1">Enable</option>
                                    <option value="0">Disable</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group className="mb-15" controlId="s_discount_pp">
                                <Form.Label>Post Purchase Discount %</Form.Label>
                                <Form.Control type="number" name="discount_value_pp" value={this.state.discount_value_pp} onChange={this.handleChange}/>
                            </Form.Group>

                            
                            <Form.Group className="mb-15" controlId="s_status">
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" name="status" custom value={this.state.status} onChange={this.handleChange} >
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                </Form.Control>
                            </Form.Group>
                            
                            
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" type="submit">
                            Save Changes
                        </Button>
                        </Modal.Footer>
                        </Form>
                    </Modal>


                    {/*SMS details modal*/}
                    <Modal show={this.state.showSmsDetails} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Form onSubmit={this.handleSave}>
                            <Modal.Header closeButton>
                                <Modal.Title>SMS Settings</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form.Group className="mb-15" controlId="s_twilio_number">
                                    <Form.Label>Twilio Number</Form.Label>
                                    <Form.Control type="text" name="twilio_number" value={this.state.twilio_number} onChange={this.handleChange} />
                                </Form.Group>

                                <Form.Group className="mb-15" controlId="s_queue_sms">
                                    <Form.Label>Queue SMS</Form.Label>
                                    <Form.Control as="textarea" rows={4} name="sms_template" value={this.state.sms_template} onChange={this.handleChange} />
                                    <Form.Text className="text-muted">
                                        Fields: <b>{`{customer_name}`}</b> = Customer name, <b>{`{agent_name}`}</b> = Agent name, <b>{`{shop_name}`}</b> = Shop name, <b>{`{product_name}`}</b> = Product name.
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-15" controlId="s_queue_sms_pp">
                                    <Form.Label>Post Purchase SMS</Form.Label>
                                    <Form.Control as="textarea" rows={4} name="sms_template_pp" value={this.state.sms_template_pp} onChange={this.handleChange} />
                                    <Form.Text className="text-muted">
                                        Fields: <b>{`{customer_name}`}</b> = Customer name, <b>{`{agent_name}`}</b> = Agent name, <b>{`{shop_name}`}</b> = Shop name, <b>{`{product_name}`}</b> = Product name, <b>{`{pp_discount}`}</b> = Post Purchase discount value, <b>{`{discount_code}`}</b> = Discount Code, <b>{`{store_name}`}</b> = Store name, <b>{`{store_url}`}</b> = Store Domain.                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-15" controlId="s_queue_sms_ff">
                                    <Form.Label>Follow Up SMS</Form.Label>
                                    <Form.Control as="textarea" rows={4} name="sms_template_ff" value={this.state.sms_template_ff} onChange={this.handleChange} />
                                    <Form.Text className="text-muted">
                                        Fields: <b>{`{customer_name}`}</b> = Customer name, <b>{`{agent_name}`}</b> = Agent name, <b>{`{shop_name}`}</b> = Shop name </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-15" controlId="s_agent_name">
                                    <Form.Label>Agent Name</Form.Label>
                                    <Form.Control type="text" name="agent_name" value={this.state.agent_name} onChange={this.handleChange} />
                                </Form.Group>
                                
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                            </Modal.Footer>
                        </Form>
                </Modal>


                

                
                {/*Delete modal*/}
                <Modal show={this.state.showDelete} onHide={this.handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter" centered>
                    <Form onSubmit={this.handleDelete}>
                        <Modal.Header closeButton>
                            <Modal.Title>Delete shop</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.delerror === true ? <Alert variant="danger">Incorrect "delete msg".</Alert> : ""}
                            <Form.Group className="mb-15" controlId="s_twilio_number">
                                <Form.Label>Type in "delete" to remove this shop from Cart Rescue.</Form.Label>
                                <Form.Control type="text" name="delete" required="true" />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="danger" type="submit">
                            Delete
                        </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        
        )
    }
}

export default withRouter(ShopsView);